<template>
  <div class="container">
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入电影名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="datearray">
          <el-date-picker
            v-model="query.datearray"
            type="daterange"
            :picker-options="queryPickerOptions"
            range-separator="至"
            start-placeholder="上映开始日期"
            end-placeholder="上映结束日期"
            align="right"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="status">
          <el-select v-model="query.status" placeholder="请选择状态" clearable>
            <el-option label="上架" value="1"></el-option>
            <el-option label="下架" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>
    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-dblclick="onEdit"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column label="电影名称" prop="name" width="180" />
      <el-table-column label="演员" prop="actor" />
      <el-table-column label="电影类型" prop="type" />
      <el-table-column
        label="发布时间"
        prop="createTime"
        width="180"
        sortable
      />
      <el-table-column label="上映时间" prop="onlineTime" sortable />
      <el-table-column label="时长" prop="duration" />
      <el-table-column label="影讯图片地址" prop="pic" width="120">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.pic"
            fit="fit"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        label="用户订票上限"
        prop="nums"
        width="100"
        align="center"
      />
      <el-table-column
        label="总座位数"
        prop="seats"
        width="80"
        align="center"
      />
      <el-table-column label="状态" prop="status" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="danger">下架</el-tag>
          <el-tag v-if="scope.row.status == 1" type="success">上架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
          <el-dropdown>
            <el-button>
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="onStatus(scope.row, 1)">
                上架
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 0)">
                下架
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑电影资讯 -->
    <el-dialog
      title="编辑电影资讯"
      width="50%"
      :visible.sync="selectDlgShow"
      fullscreen
      center
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="电影名称" prop="name" required>
            <el-input
              v-model="form.name"
              type="text"
              placeholder="请输入电影名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="内容介绍" prop="content" required>
            <quill-editor
              ref="editor"
              v-model="form.content"
              :options="editorOption"
            ></quill-editor>
          </el-form-item>
          <el-form-item label="演员" prop="actor" required>
            <el-input
              v-model="form.actor"
              type="text"
              placeholder="请输入演员"
              clearable
            />
          </el-form-item>
          <el-form-item label="电影类型" prop="type" required>
            <el-input
              v-model="form.type"
              type="type"
              placeholder="请输入电影类型"
              clearable
            />
          </el-form-item>
          <el-form-item label="上映时间" prop="onlineTime" required>
            <el-date-picker
              v-model="form.onlineTime"
              align="right"
              type="date"
              placeholder="请选择上映时间"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="时长" prop="duration" required>
            <el-input-number
              v-model="form.duration"
              :min="0"
              :max="200"
              clearable
            ></el-input-number>
          </el-form-item>
          <el-form-item label="用户订票上限" prop="nums" required>
            <el-input-number
              v-model="form.nums"
              :min="0"
              :max="99"
              clearable
            ></el-input-number>
          </el-form-item>
          <el-form-item label="座位数" prop="seats" required>
            <el-input-number
              v-model="form.seats"
              :min="0"
              :max="200"
              clearable
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveInfo, getInfoList, updateInfoStatus, delInfo } from '@/api/movie';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import { quillRedefine } from 'vue-quill-editor-upload';
import request from '@/utils/request';
import quillConfig from '@/assets/js/quill-config';

export default {
  name: 'MovieInfo',
  components: {
    quillEditor
    // quillRedefine
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      queryPickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      form: {
        name: '',
        content: '',
        actor: '',
        type: '',
        onlineTime: '',
        pic: '',
        duration: 0,
        nums: 5,
        seats: 100
      },
      query: {
        name: '',
        datearray: '',
        status: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: '',
        status: '',
        beginDate: '',
        endDate: ''
      },
      orderTypeList: [],
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      editorOption: {}
    };
  },
  created() {
    this.fetchData();
    this.imgUpload();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      if (this.query.datearray != null) {
        let beginDate = this.query.datearray[0];
        let endDate = this.query.datearray[1];
        this.pageInfo.beginDate = beginDate;
        this.pageInfo.endDate = endDate;
      } else {
        this.pageInfo.beginDate = '';
        this.pageInfo.endDate = '';
      }
      this.pageInfo.name = this.query.name;
      this.pageInfo.status = this.query.status;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //添加
    onAdd() {
      this.form = {
        name: '',
        content: '',
        actor: '',
        type: '',
        onlineTime: '',
        pic: '',
        duration: 0,
        nums: 5,
        seats: 50
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        content: row.content,
        actor: row.actor,
        type: row.type,
        onlineTime: row.onlineTime,
        duration: row.duration,
        nums: row.nums,
        seats: row.seats
      };
      this.selectDlgShow = true;
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getInfoList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delInfo({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此电影吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delInfo({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //验证表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    //保存与修改
    save() {
      this.dlgLoading = true;
      saveInfo(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    //上下架
    onStatus(row, status) {
      updateInfoStatus({ id: row.id, status: status }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.onRefresh();
        } else {
          this.$message.error('操作失败');
        }
      });
    },
    imgUpload() {
      this.editorOption = quillRedefine({
        // 图片上传的设置
        uploadConfig: {
          action: request.defaults.baseURL + '/movie/manager/uploadPicInfo',
          res: response => {
            let imgPath = '';
            if (response.code === 200) {
              this.form.pic = response.data.savePath;
              imgPath = response.data.urlPath;
            }
            return imgPath;
          },
          methods: 'POST',
          name: 'file',
          size: 2048,
          accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
        },
        placeholder: '请输入您想编辑的内容',
        toolOptions: quillConfig.toolOptions
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.el-dropdown {
  margin-left: 10px;
}
</style>
